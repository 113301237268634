<template>
  <header class="fixed-top">
    <nav class="navbar navbar-expand-xl navbar-dark navbar-bg-color" role="navigation">
      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#bs-Login-navbar-collapse-1"
          aria-controls="bs-Login-navbar-collapse-1"
          aria-expanded="false"
          aria-label="Toggle navigation">
        <span class="sr-only">Навигация</span> <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="bs-Login-navbar-collapse-1">
        <ul class="navbar-nav me-auto">
          <nav-item
              v-for="item in navItems"
              :key=item.id
              :id=item.id
              :link=item.link
              :title=item.title.toUpperCase()
          />
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item me-3">
            <span v-if="registration">
              <span class="text-light"><BaseIcon name="user" width="18" height="18"/>&nbsp;{{ user }}&nbsp;</span>
              <button type="submit" class="btn btn-warning" name="logout" @click.prevent="logout">
              <BaseIcon name="exit" width="18" height="18"/>&nbsp;ВЫХОД
              </button>
            </span> <span v-else>
            <button type="button" class="btn btn-success" @click.prevent="viewLogin">
              <BaseIcon name="user" width="18" height="18"/>ВХОД
            </button>
            </span>
          </li>
        </ul>
      </div>
    </nav>
    <NavHr/>
  </header>
  <LoginPage
      @login="login"
      ref="loginModal"
  />
</template>

<script>
import NavItem from '@/components/nav/NavItem'
import NavHr from '@/components/nav/NavHr'
import LoginPage from '@/components/nav/LoginPage'

import privateApi from '@/plugins/private'
import BaseIcon from "@/components/BaseIcon";


export default {
  name: 'NavBar',
  emits: ['showAlert'],
  data() {
    return {
      admin: null,
      registration: null,
      user: null,
      navItems: this.getNavs()
    }
  },
  methods: {
    viewLogin() {
      this.$refs.loginModal.openModal()
    },
    getNavs() {
      let navs = [
        {
          id: 1,
          link: '/',
          title: 'ЕПС НТО'
        },
        {
          id: 2,
          link: '/search',
          title: 'ПОИСК'
        },
        {
          id: 3,
          link: '/helper',
          title: 'СПРАВОЧНАЯ ИНФОРМАЦИЯ'
        },
        {
          id: 4,
          link: '/open-data',
          title: 'ОТКРЫТЫЕ ДАННЫЕ'
        },
        {
          id: 5,
          link: '/registry',
          title: 'РЕЕСТР РИД'
        },
        {
          id: 6,
          link: '/analytics',
          title: 'АНАЛИТИКА'
        },
        {
          id: 7,
          link: '/innovation',
          title: 'НОВИНКИ СИФ'
        },
        {
          id: 8,
          link: '/terms',
          title: 'Термины'
        },
        {
          id: 9,
          link: '/offer',
          title: 'план ниокр'
        },
        {
          id: 10,
          link: '/ntd',
          title: 'ВНЕДРЕНИЕ НТД'
        }
      ]
      if (this.admin) {
        navs.push({
          id: 11,
          link: '/admin',
          title: 'АДМИН'
        })
      }
      return navs
    },
    login(login, password) {
      this.axios
          .post('/api/auth/login', {login: login, password: password})
          .then(res => {
            const user = {
              name: res.data.user.last_name,
              role: res.data.user.role,
              token: res.data.token,
              email: res.data.user.email,
              fax: res.data.user.fax,
              first_name: res.data.user.first_name,
              last_name: res.data.user.last_name,
              middle_name: res.data.user.middle_name,
              organization: res.data.user.organization,
              phone: res.data.user.phone,
              position: res.data.user.position,
            }
            localStorage.setItem('user', JSON.stringify(user))
            this.getRegistration()
            this.$refs.loginModal.closeModal()
            this.$emit('showAlert', `Добро пожаловать, ${user.name}`, 'info')
            setTimeout(() => {
              location.reload()
            }, 1000);
          })
    },
    logout() {
      privateApi
          .post('/api/auth/logout')
          .then(() => {
            const user = JSON.parse(localStorage.getItem('user'))
            this.$emit('showAlert', `До встречи, ${user.name}`, 'info')
            localStorage.removeItem('user')
            this.getRegistration()
            setTimeout(() => {
              location.reload()
            }, 1000);
          })
          .catch(error => {
            this.$emit('showAlert', error.response.data.error, 'warning')
          })
    },
    getRegistration() {
      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'))
        this.user = user.name
        this.registration = true
        this.admin = user.role === 'Admin'
      } else {
        this.user = null
        this.admin = false
        this.registration = false
      }
      this.navItems = this.getNavs();
    },
  },
  mounted() {
    this.getRegistration()
  },
  components: {
    BaseIcon,
    NavItem,
    NavHr,
    LoginPage
  }
}
</script>

<style scoped>

</style>
