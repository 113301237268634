<template>
  <div class="loader" v-show="view"></div>
</template>

<script>
export default {
  name: "TheLoader",
  props: [
    'view'
  ]
}
</script>

<style scoped>
</style>
