<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      fill="currentColor"
      :viewBox="`0 0 ${width} ${height}`"
      v-html="paths"
  />
</template>

<script>
import icons from '@/plugins/icon'

export default {
  props: {
    width: {
      type: String,
      default: "16"
    },
    height: {
      type: String,
      default: "16"
    },
    name: String
  },
  data() {
    return {
      paths: icons[this.name]
    }
  },
  name: "BaseIcon"
}
</script>

<style scoped>

</style>
