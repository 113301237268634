<template>
  <li>
    <a :href="link" target="_blank" :alt="alt">
      <BaseIcon v-if="icon" :name="icon"/>
      <span v-html="title"></span> </a>
  </li>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";

export default {
  components: {BaseIcon},
  props: {
    link: String,
    alt: String,
    title: String,
    icon: String
  },
  name: 'HelpItem'
}
</script>

<style scoped>

</style>
