<template>
  <div class="container-fluid underbar-bg-color">
    <span><a :href="link"><img src="/img/logo_alt.svg" :alt="title">{{ title }}</a></span>
  </div>
</template>

<script>
export default {
  name: 'NavHr',
  data () {
    return {
      title: 'ФЕДЕРАЛЬНОЕ ДОРОЖНОЕ АГЕНТСТВО',
      link: 'http://rosavtodor.ru'
    }
  }
}
</script>

<style scoped>
img {
  margin-Top: -5px;
  text-align: center;
  width: 20px;
}
</style>
