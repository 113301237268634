<template>
  <footer :class="isMain ? 'footer-main' : 'footer'">
    <main-footer v-if="isMain"></main-footer>
    <other-footer v-else></other-footer>
  </footer>
</template>

<script>
import MainFooter from "@/components/footer/MainFooter";
import OtherFooter from "@/components/footer/OtherFooter";
import {useRoute} from 'vue-router'

export default {
  name: 'FooterBlock',
  data() {
    return {
      isMain: useRoute().name  == 'landing'
    }
  },
  components: {
    MainFooter,
    OtherFooter
  }
}
</script>

<style scoped>

</style>
