<template>
  <div class="container">
    <p>
      <span class="text-muted">Публикация материалов не допускается без наличия ссылки на источник</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "OtherFooter"
}
</script>

<style scoped>
</style>
