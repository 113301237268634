import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import router from "@/plugins/router";
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')


