<template>
  <div
      class="modal fade"
      id="LoginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="LoginModalLabel"
      aria-hidden="true" style="z-index:2000;"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="LoginModalLabel">Вход в личный кабинет</h5>
          <button type="button" class="btn-close" @click.prevent="closeModal" aria-label="Close"></button>
        </div>

        <!-- Форма ввода логина и пароля -->
        <div class="modal-body">
          <form>
            <label for="InputLogin">Введите имя пользователя:</label>
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="bi bi-person-fill"></i></span> <input
                type="text"
                class="form-control"
                name="username"
                v-model="name"
                id="InputLogin"
                aria-label="Имя пользователя"
                aria-describedby="basic-addon_login"
                placeholder="Имя пользователя"
            >
            </div>
            <small id="LoginHelp" class="form-text text-muted"> Для получения доступа к личному кабинету свяжитесь с
                                                                администратором </small> <br> <label
              for="InputPassword">Введите пароль:</label>
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="bi bi-lock-fill"></i></span> <input
                type="password"
                class="form-control"
                name="password"
                v-model="password"
                id="InputPassword"
                placeholder="Пароль">
            </div>
            <hr>
            <div style="display: flex; flex-direction: column; justify-content: space-between;">
              <span>Заходя в Личный кабинет, вы выражаете согласие на обработку персональных данных согласно
                <a href="/docs/Politika obrabotki PDN.pdf" target="_blank">
                  Политике в области обработки персональных данных
                </a> и <a href="/docs/Polojenie o zashite PDN.pdf" target="_blank">
                  Положению о порядке обработки и защите персональных данных</a> ФГБУ&nbsp;«ИНФОРМАВТОДОР».</span>
              <span style="align-self: self-end;">
                <a href="/docs/Uvedomlenie o PDN.pdf" target="_blank">Подробнее</a>
              </span>
            </div>
            <hr>
            <button @click.prevent="login" name="login" class="btn btn-primary">Войти</button>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click.prevent="closeModal">Закрыть окно</button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default {
  name: "LoginPage",
  emits: ['login'],
  data() {
    return {
      name: null,
      password: null,
      loginModal: null,
    }
  },
  methods: {
    openModal() {
      this.loginModal.show()
    },
    closeModal() {
      this.loginModal.hide()
    },
    login() {
      this.$emit('login', this.name, this.password)
    }

  },
  mounted() {
    this.loginModal = new bootstrap.Modal('#LoginModal', {})
  },
}
</script>

<style scoped>

</style>
