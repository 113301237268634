import {createRouter, createWebHistory} from 'vue-router'

const route = new createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('@/components/TheLanding'),
            name: "landing"
        },
        {
            path: '/search',
            component: () => import('@/components/TheSearch'),
            children: [
                {
                    path: '/search',
                    name: "global-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/search/global/TheSearchGlobal')
                        }
                },
                {
                    path: "nioktr",
                    name: "nioktr-search",
                    components:
                        {
                            searchView: () => import('@/components/search/nioktr/TheNioktrSearch')
                        }
                },
                {
                    path: "eb-ntd",
                    name: "eb-ntd-search",
                    components:
                        {
                            searchView: () => import('@/components/search/eb-ntd/TheEbNtdSearch')
                        }
                },
                {
                    path: "eb-patents",
                    name: "eb-patents-search",
                    components:
                        {
                            searchView: () => import('@/components/search/eb-patents/TheEbPatentsSearch')
                        }
                },
                {
                    path: "ats-dh",
                    name: "ats-dh-search",
                    components:
                        {
                            searchView: () => import('@/components/search/ats-dh/TheAtsDhSearch')
                        }
                },
                {
                    path: "special",
                    name: "special-search",
                    components:
                        {
                            searchView: () => import('@/components/search/special/TheSpecialSearch')
                        }
                },
                {
                    path: "tender",
                    name: "tender-search",
                    components:
                        {
                            searchView: () => import('@/components/search/tender/TheTenderSearch')
                        }
                },
                {
                    path: "information",
                    name: "information-search",
                    components:
                        {
                            searchView: () => import('@/components/search/information/TheInformationSearch')
                        },
                    children: [
                        {
                            path: "",
                            name: "information-monitoring-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/monitoring/TheMonitoringSearch')
                                }
                        },
                        {
                            path: "realization",
                            name: "information-realization-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/realization/TheRealizationSearch')
                                }
                        }
                        ,
                        {
                            path: "act",
                            name: "information-act-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/act/TheActSearch')
                                }
                        },
                        {
                            path: "contractor",
                            name: "information-contractor-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/contractor/TheContractorSearch')
                                }
                        },
                        {
                            path: "form",
                            name: "information-form-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/form/TheFormSearch')
                                }
                        },
                        {
                            path: "classification",
                            name: "information-classification-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/classification/TheClassificationSearch')
                                }
                        },
                        {
                            path: "project",
                            name: "information-project-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/project/TheProjectSearch')
                                }
                        },
                        {
                            path: "assertion",
                            name: "information-assertion-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/assertion/TheAssertionSearch')
                                }
                        },
                        {
                            path: "balance",
                            name: "information-balance-search",
                            components:
                                {
                                    searchInformationView: () => import('@/components/search/information/balance/TheBalanceSearch')
                                }
                        }
                    ]
                },
                {
                    path: "/search/events",
                    name: "events-search",
                    components:
                        {
                            searchView: () => import('@/components/search/events/TheEventsSearch')
                        }
                }
            ]
        },
        {
            path: '/helper',
            component: () => import('@/components/TheHelper'),
            name: "helper"
        },
        {
            path: "/open-data",
            component: () => import('@/components/TheOpenData'),
            name: "open-data"
        },
        {
            path: "/registry",
            component: () => import('@/components/TheRegistry'),
            name: "registry"
        },
        {
            path: "/analytics",
            component: () => import('@/components/TheAnalytics'),
            name: "analytics"
        },
        {
            path: "/innovation",
            name: "innovation",
            component: () => import('@/components/TheInnovation'),
        },
        {
            path: '/terms',
            component: () => import('@/components/TheSearch'),
            children: [
                {
                    path: '/terms',
                    name: "terms-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/search/terms/TheTerms')
                        }
                },
                {
                    path: 'docs',
                    name: "docs-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/search/terms/TheDocsTerms')
                        }
                }
            ]
        },
        {
            path: '/offer',
            component: () => import('@/components/TheSearch'),
            children: [
                {
                    path: '',
                    name: "offer-ca-fda-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/offer/OfferCaFda')
                        }
                },
                {
                    path: 'offer-organization',
                    name: "offer-organization-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/offer/OfferOrganization')
                        }
                },
                {
                    path: 'offer-review',
                    name: "offer-review-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/offer/OfferReview')
                        }
                },
                {
                    path: 'offer-list',
                    name: "offer-list-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/offer/OfferList')
                        }
                }
            ]
        },
        {
            path: '/ntd',
            component: () => import('@/components/TheSearch'),
            children: [
                {
                    path: '',
                    name: "input-ntd-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/ntd/InputNtd')
                        }
                },
                {
                    path: 'find-ntd',
                    name: "find-ntd-search",
                    components:
                        {
                            searchView: () =>
                                import('@/components/ntd/FindNtd')
                        }
                }
            ]
        },
        {
            path: "/admin",
            name: "admin",
            component: () => import('@/components/TheAdmin'),
            children: [
                {
                    path: "",
                    name: "admin-users",
                    component: () => import('@/components/admin/users/TheUserTable')
                },
                {
                    path: "migration",
                    name: "admin-migration",
                    component: () => import('@/components/admin/migration/TheMigration')
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'ThePage404',
            component: () => import('@/components/ThePage404')
        }
    ]
})

route.beforeEach((to, from, next) => {
    next()
})

export default route
