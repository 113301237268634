import axios from "axios";

const privateApi = axios.create();

privateApi.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'))
        let token = user ? user.token : null
        if (token) {
            config.headers.authorization = `Bearer ${token}`
        }
        return config
    }, error => {
        return error
    })

privateApi.interceptors.response.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'))
        let token = user ? user.token : null
        if (token) {
            config.headers.authorization = `Bearer ${token}`
        }
        return config
    }, error => {
        const user = JSON.parse(localStorage.getItem('user'))
        let token = user ? user.token : null

        if (token && error.response.data.message === 'Token has expired') {
            return axios.post('/api/auth/refresh', {}, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then(res => {
                    token = res.data.token
                    user.token = res.data.token
                    localStorage.setItem('user', JSON.stringify(user))
                    error.config.headers.authorization = `Bearer ${token}`
                    return privateApi.request(error.config)
                }
            )
        }
        return error
    }
)

export default privateApi;
