<template>
  <input type="checkbox" id="nav-helper-toggle" hidden="">
  <transition name="slide-fade">
    <nav class="nav-helper" style="z-index: 999999;">
      <label for="nav-helper-toggle" class="nav-helper-toggle" @click=showNavbar></label>
      <h2> Помощь </h2>
      <ul>
        <help-item
            v-for="item in helpItems"
            :key=item.id
            :link=item.link
            :alt=item.alt
            :icon=item.icon
            :title=item.title
        >
        </help-item>
      </ul>
    </nav>
  </transition>
</template>

<script>
import HelpItem from '@/components/landing/HelpItem'

export default {
  name: 'HelpBlock',
  data() {
    return {
      isView: false,
      helpItems: [
        {
          id: 1,
          link: '/docs/Politika obrabotki PDN.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Политика обработки персональных данных'
        },
        {
          id: 2,
          link: '/docs/Polojenie o zashite PDN.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Положение о защите персональных данных'
        },
        {
          id: 3,
          link: '/docs/Uvedomlenie o PDN.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Уведомление об использовании персональных данных'
        },
        {
          id: 4,
          link: '/docs/info/instruction.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Инструкция пользователя'
        },
        {
          id: 5,
          link: '/docs/info/podrazdel.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Разделы и подразделы Международной патентной классификации (МПК)'
        },
        {
          id: 6,
          link: '/docs/info/class_ntd.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Классификатор НТД'
        },
        {
          id: 7,
          link: '/docs/info/class_ebntd.pdf',
          alt: 'Открыть/скачать файл в формате PDF',
          icon: 'question',
          title: 'Тематика ЭБ НТД'
        },
        {
          id: 8,
          link: '#CriticalModal',
          icon: 'search',
          title: 'Критические технологии'
        },
        {
          id: 9,
          link: '#PriorityModal',
          icon: 'search',
          title: 'Приоритетные направления'
        },
        {
          id: 10,
          link: '#GlosModal',
          icon: 'search',
          title: 'Глоссарий'
        },
        {
          id: 11,
          link: 'tel:+74957479251',
          alt: 'Телефон технической поддержки: +7 (495) 747-92-51',
          icon: 'phone',
          title: '+7 (495) 747-92-51'
        },
        {
          link: 'mailto:sif@infad.ru',
          id: 12,
          alt: 'Служба поддержки «ЭБ НТД»: sif@infad.ru',
          title: 'Служба поддержки<br>«ЭБ НТД»<br>sif@infad.ru',
          icon: 'mail'
        },
        {
          id: 13,
          link: 'mailto:eps_nto@infad.ru',
          alt: 'Служба поддержки «ЕПС НТО»: eps_nto@infad.ru',
          title: 'Служба поддержки<br>«ЕПС НТО»<br> eps_nto@infad.ru',
          icon: 'mail'
        }
      ]
    }
  },
  components: {
    'help-item': HelpItem
  },
  methods: {
    showNavbar() {

    }
  }
}
</script>

<style scoped>

</style>
