<template>
    <div class="container">
      <div class="row mb-3 mt-3">
        <div class="col-12 text-center">
          <span class="second-header">
            <strong>ФЕДЕРАЛЬНОЕ ДОРОЖНОЕ АГЕНТСТВО «РОСАВТОДОР»</strong>
          </span>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-12 text-center">
          <span class="second-content">
           © 2001-2023. Публикация материалов не допускается без наличия ссылки</span>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-12 text-center">
          <span class="second-content">
            Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie,
            которые обеспечивают правильную работу сайта.</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>

</style>
