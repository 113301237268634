<template>
  <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 99999">
    <div :class="['toast', 'align-items-center', 'fw-bold', 'alert', 'border-0', type]"  role="alert" aria-live="assertive"
         aria-atomic="true" id="toast">
      <div class="d-flex">
        <div class="toast-body">
          {{ message }}
        </div>
        <button type="button" class="btn-close me-2 m-auto" @click.prevent="closeToast"></button>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";

export default {
  data() {
    return {
      toast: null,
      message: null,
      type: 'bg-info-subtle'
    }
  },
  mounted() {
    this.toast = new bootstrap.Toast('#toast', {})
  },
  methods: {
    showToast(message, type) {
      this.message = message
      this.type = `bg-${type}-subtle`
      this.toast.show()
    },
    closeToast() {
      this.toast.hide()
    },
  },
  name: "AlertToast"
}
</script>

<style scoped>

</style>
