<template>
  <router-link
      :to="link"
      custom
      v-slot="{ href, navigate, isActive}"
  >
    <li class="nav-item" :class="[isActive ? 'border_on nav-item' : 'border_off']">
      <a class="nav-link" :class="[isActive && 'active']" :href="href" @click="navigate"> {{ title }}</a>
    </li>
  </router-link>
</template>

<script>
export default {
  props: {
    link: String,
    title: String,
    id: Number,
  },
  name: 'NavItem'
}
</script>

<style scoped>

</style>

