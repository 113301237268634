<template>
  <TheLoader :view="viewLoader"/>
  <NavBar
      @show-alert="showAlert"
  />
  <article id="article" class="article-default">
    <router-view v-slot="{ Component, route }" name="default">
      <transition name="fade" mode="out-in">
        <div :key="route.path">
          <component :is="Component" @show-alert="showAlert" @loader="loader"></component>
        </div>
      </transition>
    </router-view>
  </article>
  <AlertToast ref="alert"/>
  <FooterBlock/>
  <HelpBlock/>
</template>

<script>
import NavBar from '@/components/nav/NavBar'
import FooterBlock from '@/components/FooterBlock'
import HelpBlock from '@/components/landing/HelpBlock'
import AlertToast from "@/components/nav/AlertToast";
import TheLoader from "@/components/TheLoader";

export default {
  name: 'App',
  data() {
    return {
      message: null,
      type: null,
      viewLoader: false
    }
  },
  components: {
    AlertToast,
    NavBar,
    FooterBlock,
    HelpBlock,
    TheLoader
  },
  methods: {
    showAlert(message, type) {
      this.$refs.alert.showToast(message, type)
    },
    loader(view) {
      this.viewLoader = view
    }
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
